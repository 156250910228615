import "./form.scss";
import Header from '../Components/Header'
import Button from "../Components/Button";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useMemo, useState } from "react";
import config from "../config";
import { ReactComponent as ErrorIcon } from "../assets/error.svg";
import { ReactComponent as SuccessIcon } from "../assets/success.svg";
import { useQuery } from "react-query";

export default function Form({ onOpenPopUp }) {
    const [fields, setFields] = useState({});
    const [message, setMessage] = useState('');

    const formInfoQuery = useQuery({
        queryKey: ['formInfoQuery'],
        queryFn: () => {
            const params = new URLSearchParams();
            params.append("token", config.SOLU_FORM_APP_TOKEN);
            params.append("formId", config.SOLU_FORM_FORM_ID);
            return fetch(`${config.SOLU_FORM_API_BASE}/clients/app/forms/details?` + params.toString())
                .then(res => res.json())
                .catch(error => console.log(error.message))
        }
    });

    const OnChangeInputValue = ({ name, value }) => {
        setFields({ ...fields, [name]: value })
    }

    const OnSubmit = async (e) => {
        try {
            e.preventDefault();
            const params = new URLSearchParams();
            params.append("token", config.SOLU_FORM_APP_TOKEN);
            const target = `${config.SOLU_FORM_API_BASE}/clients/app/leads/create?` + params.toString();

            const formattedFields = {...fields};
            const phonePrefix = formInfoQuery.data.helperData.phoneData.find(d => d.country === formattedFields.phone.country).prefix;
            if (formattedFields.phone.number.startsWith("0")) {
                formattedFields.phone.number = formattedFields.phone.number.slice(1);
            }
            if (formattedFields.phone.number.startsWith(phonePrefix)) {
                formattedFields.phone.number = formattedFields.phone.number.slice(phonePrefix.length);
            }
            formattedFields.phone = `${phonePrefix}${formattedFields.phone.number}`;

            const response = await fetch(target, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    formId: config.SOLU_FORM_FORM_ID,
                    data: formattedFields
                }),
            });
            
            // const emailTarget = new URL(config.API_BASE + "/leads/new");
            // const response = await fetch(emailTarget, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(fields),
            // });

            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            };

            const responseData = await response.json();
            console.log(responseData);
            setMessage('Your form has been successfully submitted. Thank you !')
        } catch (err) {
            console.error("Error:", err.message);
            setMessage('Oops! something was wrong, please resent the message.')
        }
    };
    const onTryAgain = () => {
        setMessage('');
        setFields({});
    }
    return (
        <div id="form" className='form-container' style={{ position: "relative" }}>
            <Header name={"Form"} />
            <div className="form-content">
                <div className="content">
                    <div className="ovoky-text-style-title-H1">Want to get in touch ? <br /> Drop a line </div>
                    <Button name={"Start for free"} theme={"ovoky-comp-button-big-blue-bg"} icon={<ArrowRightAltIcon />} onClick={onOpenPopUp} />
                </div>
                <div className="line"></div>
                {
                    message ?
                        <div className="messages">
                            {message.includes("successfully")
                                ?
                                <div className="success">
                                    <SuccessIcon />
                                    <p className="success-message">
                                        {message}
                                    </p>
                                    <Button btnColor={"var(--g-95)"} btnBgColor={"var(--green-cyan)"} href={"#pricing"} name={"Check prices"} theme={"ovoky-comp-button-small-button"} />
                                </div>
                                :
                                <div className="error">
                                    <ErrorIcon />
                                    <p className="error-message">
                                        {message}
                                    </p>
                                    <Button onClick={onTryAgain} btnColor={"#FFE5EA"} btnBgColor={"#FF002E"} name={"Try again"} theme={"ovoky-comp-button-small-button"} />
                                </div>
                            }
                        </div>
                        : <div className="form">
                            <form action="" onSubmit={OnSubmit}>
                                <div>
                                    {
                                        formInfoQuery.data &&
                                        formInfoQuery.data.formData.fields.map(({ name, type, title }) => (
                                            <FormInput
                                                isBox={name === "message"}
                                                key={name}
                                                label={title}
                                                type={type}
                                                name={name}
                                                value={type !== "phone" ? (fields[name] || "") : (fields[name] || { country: "FR", number: "" })}
                                                onChange={(v) => OnChangeInputValue({ name: v.name, value: v.value })}
                                                phoneData={(type === "phone") ? formInfoQuery.data.helperData.phoneData : []}
                                            />
                                        ))
                                    }
                                </div>
                                <Button name={"Send"} theme={"ovoky-comp-button-small-button"} />
                            </form>
                        </div>
                }
                <img className="icon-form" src="/images/form-icon.png" alt="" />
            </div>
        </div>
    )
}

const FormInput = ({ value, name, label, type, onChange, phoneData, isBox }) => {
    const uuid = useMemo(() => crypto.randomUUID(), []);
    return (
        <div className="ov-comp-form-input">
            <label htmlFor={uuid} className="ovoky-text-style-p1-body">{label}</label>
            <div className="input">
                {
                    type === "phone" &&
                    <div className="phone-prefix">
                        <FormIconSelect
                            selected={value.country}
                            options={phoneData.map(({ country, prefix }) => ({ title: prefix, value: country, icon: <img alt={`flag of ${country}`} src={`${config.SOLU_FORM_API_BASE}/clients/app/assets/flag/?token=${config.SOLU_FORM_APP_TOKEN}&code=${country}`} /> }))}
                            onChange={(v) => {
                                onChange({ name, value: { country: v, number: value.number } })
                            }}
                        />
                    </div>
                }
                {
                    !isBox &&
                    <input autoComplete="on" value={type !== "phone" ? value : value.number} type={type || "text"} id={uuid} name={name} onChange={(e) => onChange({ name, value: type !== "phone" ? e.target.value : { country: value.country, number: e.target.value } })} required />
                }
                {
                    isBox &&
                    <textarea value={type !== "phone" ? value : value.number} type={type || "text"} id={uuid} name={name} onChange={(e) => onChange({ name, value: type !== "phone" ? e.target.value : { country: value.country, number: e.target.value } })} required />
                }
            </div>
        </div>
    )
}

const FormIconSelect = ({ selected, options, onChange }) => {

    const [isOpen, setIsOpen] = useState(false);
    const classList = ["ov-comp-form-icon-select"];
    isOpen && classList.push("open");

    return (
        <div className={classList.join(" ")}>
            <button className="selected" onClick={() => setIsOpen(v => !v)}>
                <div className="icon">{options.find(o => o.value === selected).icon}</div>
                <div className="title">{options.find(o => o.value === selected).title}</div>
            </button>
            <div className="options">
                {
                    options.sort((o1, o2) => parseInt(o1.title) > parseInt(o2.title) ? 1 : -1).map(({ title, value, icon }) => (
                        <div key={value} className={"option"} onClick={() => { setIsOpen(false); onChange(value) }}>
                            <div className="icon">{icon}</div>
                            <div className="title">{title}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}