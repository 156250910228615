import "./header.css";

export default function Header({name}) {
    return (
        <div className="ovoky-comp-header">
            <span className="ovoky-text-style-lables" htmlFor="">{name}</span>
            <div></div>
        </div>
    )
}
