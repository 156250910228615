import React, { Fragment } from 'react'

export default function Calls({ options }) {
    if (options.length === 0) {
        return;
    }

    const inbound = options.filter(o => o.inbound).map(o => ({ type: o.type, price: o.inbound }));
    const outbound = options.filter(o => o.price).map(o => ({ type: o.type, price: o.price }));
    const formattedOptions = { inbound, outbound };

    return (
        <div className="item">
            <p>Voice</p>
            {
                [formattedOptions.outbound, formattedOptions.inbound]
                    .filter(options => options.length)
                    .map((options, i) => (
                        <div className="item-content" key={i}>
                            <li className="ovoky-text-style-body-small" ><span >{!i ? "Outgoing Voice" : "Incoming voice"}</span></li>
                            {
                                options.map((option, index) => (
                                    <Fragment key={index}>
                                        <ul>
                                            <li className="ovoky-text-style-body-small" ><span >{option.type}</span></li>
                                        </ul>
                                        <p><span className="ovoky-text-style-p1-body-medium"> {option.price} € </span>/ Minute</p>
                                        {index < options.length - 1 && <div className="plan-line"></div>}
                                    </Fragment>
                                ))
                            }
                        </div>
                    ))
            }
        </div >

    )
}
