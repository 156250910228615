import "./drop-down.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMemo } from "react";

export default function DropDown({ filteredOptions, onclick, theme, isOpen, selectedOption, toggleOptions, handleInputChange, handleOptionSelect, inputValue }) {
    const classList = ["ov-comp-drop-down", theme];
    isOpen && classList.push("open");
    const searchId = useMemo(() => crypto.randomUUID(), []);

    return (
        <div className={classList.join(" ")}>
            <div className="selected" onClick={toggleOptions}>
                {selectedOption} {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
            <div className="menu">
                <input
                    id={searchId}
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Type to search..."
                    className="search"
                />
                <div className="options">

                    {filteredOptions.map((option, index) => (
                        <div
                            key={index}
                            className="option"
                            onClick={() => {
                                handleOptionSelect(option.title); if (!(typeof onChange === "function")) {
                                    return;
                                } onclick()
                            }}
                            value={option.value}
                        >
                            {option.title}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
